.editar {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.596);
   
}

.editar form {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    width: fit-content;
    height: fit-content;

    margin: auto;

    display: flex;
    flex-direction: column;

    padding: 40px;
    
    background-color: rgb(43, 57, 185);
}

.editar form h1{
    font-size: 24px;
}

.editar form span {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.editar form .closesave {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.closesave button {
    padding: 6px;
    margin-top: 10px;
    background-color: yellowgreen;
    border: 1px solid blue;
    border-radius: 5px;
    font-size: 11pt;
    cursor: pointer;
}

#dataVencimento {
    width: 235px;
}