.forgotPassword {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    
    margin: auto;

    width: fit-content;
    height: fit-content;

    background-color: rgb(43, 57, 185);
    padding: 10px;
    border-radius: 10px;
}

.forgotPassword form {

    display: flex;
    flex-direction: column;
       
}


.forgotPassword form label {
    color: yellowgreen;
    font-weight: bold;

}

.forgotPassword form input {
    height: 25px;
    border-radius: 5px;
    
    border: 1px solid rgb(43, 57, 185);
    font-size: 15px;
}

.forgotPassword form button {
    background-color: yellowgreen;
    height: 35px;
    border-radius: 5px;
    
    border: 1px solid rgb(43, 57, 185);

    color: rgb(43, 57, 185);
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
}

.forgotPassword form button:hover {
    background-color: rgb(165, 168, 192);
    
}