.details{
   position: absolute;
   left: 0; 
   right: 0;
   top: 0;
   bottom: 0;
   background-color: rgba(0, 0, 0, 0.315);
}

.details div {
    position: absolute;
    left: 0; 
    right: 0;
    top: 0;
    bottom: 0;

    width: 500px;
    height: fit-content;
    margin: auto;

    background-color: yellowgreen;

    padding: 40px;
}

.details div h1 {
    font-size: 30px;
    color: rgb(43, 57, 185);

}

.details div span{
    display: flex;
    flex-direction: column;
    color: rgb(43, 57, 185);
    justify-content: space-between;
    align-items: start;
}

.details div span h3 {
    font-size: 20px;
}

.details div button {
    background-color: rgb(43, 57, 185);
    color: white;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 5px;

    margin: 35px 0;
    cursor: pointer;
}