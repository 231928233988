.deleteTask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.39);
}


.deleteTask div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
    width: fit-content;
    height: fit-content;
    
    background-color: rgb(43, 57, 185);
    display: flex;
    flex-direction: column;

    padding: 20px;
}

.deleteTask div h1 {
    color: yellowgreen;
    font-size: 30px;
}

.deleteTask div span{
    display: flex;

    justify-content: space-around;
}

.deleteTask div span button {
    background-color: yellowgreen;
    border: 1px solid yellowgreen;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    color:rgb(43, 57, 185);
    font-weight: bold;
}

.deleteTask div span button:hover {
    background-color: rgb(208, 218, 188);;
}