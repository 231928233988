.navbar {
    background-color: yellowgreen;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.navbar span {
    font-weight: 600;
    font-size: 30px;
    padding-left: 20px;
    
}

.navbar span a {
    color: rgb(43, 57, 185);
    text-decoration: none;
}

.navbar div {
    display: flex;
}

.navbar div a {
    color: rgb(43, 57, 185);
    text-decoration: none;
    font-size: 20px;
    padding: 0px 5px;
    cursor: pointer;
}

.navbar div a:hover {
    background-color: rgba(43, 57, 185, 0.4);
}

.navbar div a div {
    position: absolute;
    background-color: rgb(43, 57, 185);
    border-radius: 5px;
}

.navbar div a div a {
    color: yellowgreen;   
}

.navbar div a div a:hover {
    color: rgba(43, 57, 185);   
    background-color: yellowgreen;
}