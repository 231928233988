.editCadastro {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background-color: rgba(0, 0, 0, 0.4);
	z-index: 10;
}


.editCadastro div {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	width: fit-content;
	height: fit-content;

	margin: auto;
	background-color: rgb(43, 57, 185);

	display: flex;
	flex-direction: column;
}

.editCadastro div form {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.editCadastro div form span { 
	display: flex;
	flex-direction: column;
	color: yellowgreen;
	
}

.editCadastro div form span label {
	font-weight: bold;
} 

.editCadastro div form span input {
	height: 30px;
	border-radius: 5px;
	font-size: 16px;
	border: 1px solid blue;

} 

.editCadastro div form button {
	background-color: yellowgreen;
	height: 30px;
	border: 1px solid yellowgreen;
	border-radius: 5px;
	cursor: pointer;
	margin: 5px 0;
	font-size: 16px;
}

.editCadastro div form button:hover {
	background-color: rgb(184, 190, 170);
}

.editCadastro div h2{
	color: yellowgreen;
	margin-left: auto;
	margin-right: auto;
}