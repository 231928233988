.menu {
    cursor: pointer;
    color: yellowgreen;
}

.menu div{
    position: absolute;
    background-color: rgb(43, 57, 185);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;
}

.menu div span {

}

.menu div span:hover {
    background-color: yellowgreen;
    color: rgb(43, 57, 185);
    
}

.invertOrder {
    display: flex;
    align-items: end;
    color: yellowgreen;
    cursor: pointer;
}