.login {

	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	width: fit-content;
	height: fit-content;

	margin: auto;

}
.login h1 {
	color: rgb(43, 57, 185);	
}

.login form {
	display: flex;
	flex-direction: column;

	background-color: rgb(43, 57, 185);
	padding: 30px;
	border-radius: 5px;
}

.login form label {
	color: yellowgreen;
	font-weight: 600;
}

.login form input {
	height: 30px;
	border-radius: 5px;
	border: 1px solid rgb(43, 57, 185);
}

.login form button {
	background-color: yellowgreen;
	border: 1px solid rgb(43, 57, 185);
	border-radius: 5px;
	height: 40px;
	margin-top: 20px;
	cursor: pointer;
	font-weight: 600;
	color: rgb(43, 57, 185);;
}

.login form button:hover {
	background-color: green;
}

.login form a {
	color: yellowgreen;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
}