* {
  font-family: sans-serif;
}

.taskManager {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 40px auto;
  
  width: 800px;
  height: fit-content;
  
}

.taskManager .taskManagerHeader {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
  align-items: center;
  background-color: rgb(43, 57, 185);

}
.taskManagerHeader h1{
  font-size: 30px;
}

.taskManagerHeader button {
  height: 40px;
  margin-left: 30px;
  border-radius: 5px;
  border: 1px solid blue;
  display: flex;
  align-items: center;
  color: rgb(1, 1, 129);
  background-color: yellowgreen;
}

.taskManagerHeader button:hover {
  background-color: rgb(60, 124, 243);
  cursor: pointer;
}

.taskManagerBody {
  padding: 0;

}

.taskManagerBody li {
  list-style: none;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: white;
  
}

.taskManagerBody li span {
  padding-right: 30px;
}

.taskManagerBody li span strong{
  font-weight: 600;

}

.taskManagerBody li a {
  cursor: pointer;
  text-decoration: underline;
  color: yellowgreen;
}

.taskManagerBody li:nth-child(odd) {
  background-color: rgb(43, 57, 185);
}

.taskManagerBody li:nth-child(odd).expired {
  background-color: rgb(153, 55, 55);
}

.taskManagerBody li:nth-child(odd).concluded {
  background-color: rgb(57, 143, 57);
}

.taskManagerBody li:nth-child(even) {
  color: rgb(43, 57, 185);
}

#optionsIcon:hover {
  background-color: yellowgreen;
  color: white;
  border-radius: 50px;
}

.taskManagerBody li button {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0px 1px;
  color: rgb(1, 1, 129);
  border: 1px solid blue;
  border-radius: 5px;
  cursor: pointer;
}

.taskManagerBody li button:hover {
  background-color: yellowgreen;

}

.expired {
  background-color: rgb(250, 129, 129);
}

.concluded {
  background-color: rgb(132, 241, 132);
}